import React,{ useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import './header.css'

import headerLogo from '../images/logo.svg'
import mobileLogo from '../images/big-logo.svg'
import phoneIcon from '../images/phone-icon.svg'
import closedBurger from '../images/closed-burger.svg'
import hamburger from '../images/hamburger.svg'


const Header = ({ link1, link2, link3, link4 }) => {
  const [nav, setNav] = useState(false)

  const toggleMenu = () => {
    setNav(!nav)
  }

  return (
  <header>
      <nav style={{ minHeight: nav ? `18em` : `4.5em`}}>
        <div style={{ minHeight: nav ? `25%` : `100%`}} className='nav-top'>
          <div className='nav-left'>
            <img src={headerLogo} alt='accountant logo' className='logo'/>
            <img src={mobileLogo} alt='accountant-logo' className='mobile-logo'/>
          </div>
          <div className='nav-right'>
                <Link to="/" style={{color: `#fff`}} activeStyle={{color: '#B62020'}}>
                  {link1}
                </Link>

                <Link to="/maryland-cpa/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link2}
                </Link>

                <Link to="/services/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link3}
                </Link>

                <Link to="/md-cpa/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link4}
                </Link>
                <div className='telephone-link'>
                  <img src={phoneIcon} alt='telephone icon'/>
                  <p>(410) 271-8259</p>
                </div>
                <div onClick={toggleMenu} className='hamburger-menu'>
                  {
                    nav ? <img  src={closedBurger} alt='hamburger icon' style={{ height: `40px`, width: `40px`}}/>
                    : <img  src={hamburger} alt='hamburger icon' style={{ height: `40px`, width: `40px`}}/> 
                  }
                </div>
          </div>
        </div>
        <div style={{ display: nav ? `flex` : `none`}} className='nav-bottom'>
                <Link to="/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link1}
                </Link>

                <Link to="/maryland-cpa/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link2}
                </Link>

                <Link to="/services/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link3}
                </Link>

                <Link to="/md-cpa/" style={{color: '#fff'}} activeStyle={{color: '#B62020'}}>
                  {link4}
                </Link>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  link1: PropTypes.string,
  link2: PropTypes.string,
  link3: PropTypes.string,
  link4: PropTypes.string,
  link5: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  link1: `home`,
  link2: `about`,
  link3: `services`,
  link4: `contact`,
}

export default Header
