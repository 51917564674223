/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Header from "./header"
import "./layout.css"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Header/>
      <div>
        <main>{children}</main>
        <footer>
          <div className='footer-top'>
            <div className='footer-top-logo'>
              <StaticImage src='../images/footer-logo.svg' />
            </div>
            <div className='footer-top-content'>
              <h3>SITEMAP</h3>
              <Link style={linkStyle} to='/'>home</Link>
              <Link style={linkStyle} to='/maryland-cpa/'>about</Link>
              <Link style={linkStyle} to='/services/'>services</Link>
              <Link style={linkStyle} to='/md-cpa/'>contact</Link>
            </div>
            <div className='footer-top-content'>
            <h3>CONTACT</h3>
            <p style={linkStyle}>(410) 271-8259</p>
            </div>
            <div className='footer-top-content'>
            <h3>REVIEW</h3>
            <a style={linkStyle} href='https://www.yelp.com/biz/kevin-rich-cpa-pasadena?osq=Accountants' target="_blank" rel="noopener noreferrer">yelp</a>
            <a style={linkStyle} href='https://g.page/r/CciOFP27-Td0EBI/review' target="_blank" rel="noopener noreferrer">google</a>
            </div>
          </div>
          <div className='footer-bottom'>
            <p>Copyright © {new Date().getFullYear()} Kevin Rich, CPA - All Rights Reserved.</p>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const linkStyle = {
  color: '#fff',
  marginTop: '5px',
  opacity: '0.5',
  textDecoration: 'none'
}

export default Layout
